.home-main {
  /*min-height: calc(100vh - 130px);*/
  min-height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.home-banner {
  display: flex;
  flex: 1 1;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  position: relative;
  background-attachment: fixed;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@media screen and (max-width: 500px) {
  .home-banner {
    padding: 10px;
  }
}

.home-desc-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 60%;
  min-width: 50%
}

.page-top-desc-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 70%
}

.side-area {
  min-width: 30%
}

@media screen and (max-width: 500px) {
  .home-desc-area {
    max-width: 100%;
    min-width: 90%
  }

  .side-area {
    min-width: 100%
  }
}

.slider-content {
  position: relative;
  z-index: 3;
}

.page-top-content {
  position: relative;
  z-index: 3;
}

.slider-content h1 {
  color: #fff;
  font-size: 45px;
  font-weight: 900;
  line-height: 55px;
  position: relative;
}

.page-top-content h1 {
  color: #fff;
  font-size: 35px;
  font-weight: 900;
  line-height: 45px;
  position: relative;
}

.home-article-area {
  padding: 30px;
  background-color: #fefefe;
}

.faq-area,
.profile-area {
  padding: 50px 0;
}

.title-bar {
  margin-bottom: 50px;
}

.faq-area h1,
.profile-area h1 {
  font-size: 35px;
  font-weight: 900;
  line-height: 55px;
  position: relative;
}

.faq-area h1:after,
.profile-area h1:after,
.title-bar h1:after {
  height: 4px;
  left: 0;
  width: 30%;
  position: absolute;
  background-color: rgb(165, 122, 42);
  bottom: -10px;
  content: " ";
}

.pg-intro-area {
  background-color: #000;
  background: url(/public/images/urum-view.jpg);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.pg-intro-area h1 {
  color: #fff;
}

.pg-intro-area p {
  line-height: 1.7;
  color: #fff;
}


@media screen and (max-width: 600px) {
  .slider-content h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .page-top-content h1 {
    font-size: 25px;
    line-height: 35px;
  }
}

.slider-content h1:after,
.page-top-content h1:after {
  height: 4px;
  left: 0;
  width: 30%;
  position: absolute;
  background-color: rgb(165, 122, 42);
  bottom: -10px;
  content: " ";
}

.slider-content p,
.page-top-content p {
  margin-top: 30px;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.7;
}


.home-cover {
  position: relative;
  min-height: 100vh;
  background: url(/public/images/urum-view.jpg);
  background-size: cover;
  z-index: 1;
}

.page-top-cover {
  position: relative;
  min-height: 80px;
  background: url(/public/images/urum-view.jpg);
  background-size: cover;
  z-index: 1;
}

.home-overlay {
  background-color: #000;
  /**/
  opacity: 0.6;
  position: absolute;
  height: 100%;
  width: 100%;
  /*   background: url(/public/images/globe-home.svg);

  background-image: url(/public/images/globe-home.svg),
    linear-gradient(to bottom right, #ffffff, #ffffff);
  background: url(/public/images/globe-home.svg),
    linear-gradient(to bottom right, #ffffff, #ffffff);
  background-blend-mode: difference; */
  z-index: 0 !important;
  background-size: cover;

  background-position: 100% 10%;
  background-size: contain;
  background-repeat: no-repeat;
}

.dark-overlay {
  background-color: #000;
  /**/
  opacity: 0.85;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0 !important;
  background-size: cover;
}

.hcta {
  margin-top: 20px;
  display: inline-block;
  padding: 10px 0;
}

.hcta a {
  padding: 17px 30px;
  color: rgb(165, 122, 42);
  border: 1px solid rgb(165, 122, 42);
  text-transform: uppercase;
  transition: .5s;
  font-size: 0.95rem;
  font-weight: 700;
  font-family: 'Playfair Display', serif;
}

.hcta a.w {
  color: #fff;
}

.hcta a:hover {
  background-color: rgb(165, 122, 42);
  color: #fff;
}


.world-map {}

.card-section {
  position: relative;
  padding: 50px 0;
  margin-top: -150px;
  z-index: 200;
}

.push-up-section {
  padding: 30px 0;
}

.card-container {
  width: 100%;
  min-height: 250px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .3), 0 15px 12px rgba(0, 0, 0, .22);
}

.card-content {
  border: 2px dashed rgb(165, 122, 42);
  border-top-style: solid;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1 1;
}

.card-top-image {
  height: 180px;
  object-fit: contain;
  position: relative;
  overflow: hidden;
}

.card-top-image img {
  border-radius: 0 0 50% 50%;
}

.conter {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -55px;
  min-height: 50px;
  min-width: 50px;
  padding: 10px;
  border-radius: 50%;
  background-color: transparent;
  z-index: 10;
}

.conto {
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  min-height: 50px;
  min-width: 50px;
  padding: 10px;
  border-radius: 50%;
  background-color: transparent;
  z-index: 10;
  border-radius: 50%;
  overflow: hidden;
}

.chano {
  display: inline-flex;
  width: 50px;
  line-height: 50px;
  height: 50px;
  position: relative;
  align-content: stretch;
  border-radius: 50%;
  overflow: hidden;
}

.chano input {
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  background-color: transparent;
  z-index: 2;
}

.chano .att {
  display: flex;
  position: absolute;
  height: 100%;
  line-height: 100%;
  width: 100%;
  left: 0;
  color: #0052fe;
  font-size: 30px;
  z-index: 1;
  border: 1px solid #000;
  border-radius: 50%;
  overflow: hidden;
}

.card-top-image img {
  max-width: 100%;
  z-index: 9
}

.card-topic {
  padding: 20px;
  margin-top: 30px;
}

.card-topic .sub {
  font-size: 14px;
  font-weight: 900;
  color: rgb(165, 122, 42);
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.card-topic h3 {
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

.card-topic p {
  text-align: center;
  color: #777;
  font-weight: 500;
}


.thinking-container {
  padding: 50px 0;
  background-color: #fafafa;
}


.thinking-desc {
  margin-bottom: 10px;
}

.thinking-desc h2 {
  font-size: 38px;
  font-weight: 900;
  position: relative;
  margin-bottom: 20px;
}

.thinking-desc h2:after {
  height: 4px;
  left: 0;
  width: 30%;
  position: absolute;
  background-color: rgb(165, 122, 42);
  bottom: -10px;
  content: " ";
}

.thinking-desc p {
  color: #888;
  margin-top: 30px;
}

.pad-container {
  border: 1px dashed rgb(165, 122, 42);
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  position: relative;

}

.pad-container::before {
  position: absolute;
  font-family: FontAwesome;
  content: "\f10d";
  top: 10px;
  left: 10px;
  color: #444;
  font-size: 50px;
  z-index: 2;
}

.pad-container::after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f10e";
  bottom: 50px;
  right: 15px;
  color: #444;
  font-size: 50px;
  z-index: 2;
}

.pad-icon {
  font-size: 20px;
  height: 50px;
  width: 50px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgb(165, 122, 42);
  border-radius: 50%;
  transition: .7s;

}

.pad-container:hover .pad-icon {
  background-color: #000;
}

.pad-head {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pad-text {
  margin-left: 15px;
}

.pad-text .sub {
  font-size: 0.7rem;
  font-weight: 900;
  text-transform: uppercase;
  color: rgb(165, 122, 42);
}

.pad-text h3 {
  font-size: 1.1rem;
  font-weight: 900;
}

.thinking-banner {
  flex: 1 1;
  padding: 20px 40px;
}

.thinking-banner img {
  max-width: 100%;
}

.pad-container p {
  font-size: 1.4rem;
  color: #000;
  font-weight: 200;
  font-style: italic;
  line-height: 1.5;
  font-family: 'Playfair Display', serif;
}

.pxy20-resp {
  padding: 20px;
}

@media screen and (max-width: 500px) {
  .pxy20-resp {
    padding: 10px;
  }
}

.black-area {
  background-color: #000;
  color: #fff;
  padding: 30px 0;
  margin: 30px 0;
}

.contact-info-area {
  position: relative;
  padding: 0 20px 0 0;
}

.contact-info-area h2 {
  font-size: 2.3rem;
  position: relative;
}

.contact-info-area p {
  margin-top: 20px;
}

.contact-info-area h2:after {
  height: 4px;
  left: 0;
  width: 30%;
  position: absolute;
  background-color: rgb(165, 122, 42);
  bottom: -10px;
  content: " ";
}

.blue-gray-area {
  background-color: #F7FAFF;
}

.contact-area {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.push-form {
  margin-top: -20px;
  margin-bottom: -20px;
  padding: 30px 20px;
  background-color: rgb(165, 122, 42);
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .push-form {
    margin-top: 0;
    position: relative;
  }
}

.blue-info {
  color: #0052fe;
}

.contact-area h3 {
  margin-bottom: 5px;
}


.home-form-control {
  flex: 1;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 0.03rem solid #000;
  font-size: 17px;
  color: #000;
  height: auto !important;
  background-color: transparent;
}


input.home-form-control {
  outline: 0;
}

input.home-form-control {
  min-width: 20px !important;
  max-width: 100%;
}

.input.hma {
  margin-bottom: 10px !important;
}

.input.iconed .home-form-control {
  padding-left: 50px;
}

select.home-form-control {
  max-width: 100%;
}

.home-form-control[aria-disabled] {
  background-color: #bbb;
}


.home-form-control::placeholder,
textarea.home-form-control::placeholder {
  font-size: 90%;
  font-weight: 400;
  font-style: italic;
  color: #333;
}

.home-form-control.err {
  border: 2px dashed red !important;
}

.input-icon.homs {
  color: #000;
}

textarea.home-form-control {
  min-height: 90px;
  height: auto;
  outline: 0;
}

button.hm-contact-btn {
  outline: 0;
  background-color: #000;
  color: #fff;
  padding: 12px 35px;
  border: 1px solid #000;
  text-transform: uppercase;
}

button.hm-contact-btn:hover {
  background-color: #444;
}

.parp {
  margin-bottom: 20px;
}

ul.cont-list {
  list-style-type: none;
  padding: 20px 0;
  margin: 0;
}

ul.cont-list li {
  display: inline-block;
  margin-right: 15px;
}

ul.cont-list li a {
  display: inline-block;
  font-size: 35px;
  color: rgb(165, 122, 42);
  transition: .5s;
}

ul.cont-list li a:hover {
  color: #fff;
}