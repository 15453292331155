.logo-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0 15px;
}

.auth-logo-space {
  margin: 65px 0 20px 0;
}

.auth-logo-space a.logo {
  display: inline-block;
  height: 50px;
}

a.logo img {
  height: 100%;
}

.in {
  left: 0 !important;
}

.out {
  left: -100% !important;
}



.sticky-nav .header-button a {
  display: inline-block;
  color: #fff;
  padding: 10px 28px;
  border: 1px solid#fff;
  text-transform: capitalize;
  transition: 0.5s;
  font-size: 15px;
  position: relative;
  z-index: 1;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 5px;
  font-weight: 400;
}

.sticky-nav.nav-menu .header_tbn {
  position: relative;
  top: 4px;
  left: 60px;
}

/*<!-- ============================================================== -->
<!-- Start header-top Css -->
<!-- ============================================================== -->*/
.header-top {
  background: #0052fe;
  padding: 9px 0;
}

.header-left-text p {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Cabin';
  margin: 0;
  color: #fff;
}

.header-icon-list {}

.header-icon-list ul li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
  color: #fff;
}

.header-icon-list ul li a {
  font-size: 14px;
  display: inline-block;
  color: #fff;
  line-height: 26px;
}

/*<!-- ============================================================== -->
<!-- Start  main-header Css -->
<!-- ============================================================== -->*/
.main-header {
  position: relative;
  z-index: 99;
  padding: 30px 0;
  background-color: transparent !important;
  border-bottom: 1p solid #eee;
}

.desktop-header-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile-header-bar {
  display: none;
}

.header-items {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ham-nav {
  display: none;
  color: #fff;
  font-size: 30px;
  transition: .6s;
}

.ham-nav a {
  color: #fff;
  transition: .6s;
}

@media screen and (max-width: 700px) {
  .main-header {
    padding: 20px 0;
  }

  .desktop-header-bar {
    display: none !important;
  }

  .ham-nav {
    display: inline-block;
  }

  .mobile-header-bar {
    display: block;
  }
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo,
.footer-logo {
  display: inline-block;
  height: 50px;
}

.header-logo img,
.footer-logo img {
  height: 100%;
}



ul.header-nav {
  display: flex;
  padding: 15px 0;
  margin: 0;
  position: relative;
  list-style-type: none;
  margin-right: 5px;
}

ul.header-nav ul li {
  display: inline-block;
}

ul.header-nav li a {
  display: inline-block;
  text-transform: uppercase;
  padding: 15px 20px;
  transition: .5s;
  position: relative;
  font-size: 0.85rem;
  color: #fff;
  font-weight: 900;
}

ul.header-nav li a:hover {
  color: rgb(165, 122, 42);
}

ul.header-nav li a:hover .icon::before {
  color: rgb(165, 122, 42);
}

ul.header-nav li.icon {
  position: relative;
}

ul.header-nav li.icon::before {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 12px;
  opacity: 1;
  top: 15px;
  right: 5px;
  left: inherit;
  position: absolute;
  color: #fff;
}


/* sub-menu */
ul.header-nav li ul {
  top: 100%;
  left: 0;
  min-width: 200px;
  background: #fff;
  transition: .5s;
  display: none;
  display: block;
  padding: 0px;
  position: absolute;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
  opacity: 0;
  overflow: hidden;
}


ul.header-nav li ul li {
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: inherit;
  border-bottom: 1px solid #DEE1E6;
}

ul.header-nav li ul li:last-child {
  border-bottom: none;
}

ul.header-nav li ul li a {
  display: inline-block;
  color: #444 !important;
  padding: 10px 25px;
  transition: .5s;
  text-transform: capitalize;
}

ul.header-nav li ul li:hover {
  background: rgb(165, 122, 42);
}

ul.header-nav li ul li:hover a {
  color: #fff !important;
}

ul.header-nav li:hover ul {
  display: inline-block !important;
  opacity: 1;
}

.sticky-nav ul.header-nav li.icon::before {
  color: rgb(165, 122, 42);
}

.header-button {
  margin-left: 20px;
}

.header-button a {
  display: inline-block;
  font-weight: 400;
  padding: 6px 20px;
  text-transform: capitalize;
  transition: 0.5s;
  font-size: 0.9rem;
  position: relative;
  z-index: 1;
  border-radius: 3px;
  overflow: hidden;
  border-radius: 25px;
  border-width: 1px;
  border-style: solid;
  margin-right: 10px;
  border-color: rgb(165, 122, 42);

}

.header-button a.login {
  color: rgb(165, 122, 42);
}

.header-button a.signup {
  background: rgb(165, 122, 42);
  color: #fff;
}

.header-button a:hover {
  color: #fff;
  background: linear-gradient(to left, rgb(165, 122, 42), #740fd6);
}

.header_tbn {
  position: relative;
  top: 4px;
  left: 15px;
}

.header_tbn a {
  display: inline-block;
  font-size: 16px;
  line-height: 40px;
  color: #010e22;
  font-weight: 400;
  font-family: "Cabin";
  text-transform: capitalize;
}

.header_tbn a i {
  display: inline-block;
  font-size: 16px;
  color: rgb(165, 122, 42);
  font-weight: 800;
  margin-right: 5px;
}

.header-post {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.phone-spot {
  padding: 10px 20px;
  background-color: rgb(165, 122, 42);
  color: #fff;
  display: flex;
  flex-direction: column;
}

.phone-text {
  font-weight: 900;
  font-size: 0.95em;
}

a.phone-spot:hover {
  background-color: #000;
}

.phone-spot em {
  font-style: italic;
  font-weight: 300;
  font-family: 'Playfair Display', serif;
}

.icon-spot {
  padding: 5px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.icon-spot img {
  height: 40px;
}

ul.mobile-header-nav {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0;
  background-color: #000;
  border-radius: 6px;
  margin-top: 10px;
}

ul.mobile-header-nav li {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #333;
}

ul.mobile-header-nav li .list-fig {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropper {
  display: inline-flex;
  display: inline-block;
}

.dropper a {
  color: #fff;
  display: inline-block;
  padding: 10px;
}

ul.mobile-header-nav li:last-child {
  border-bottom: none;
}

ul.mobile-header-nav li a {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  padding: 10px 2px;
  color: #fff;
  font-weight: 900;
}

ul.mobile-header-nav li a:hover {
  color: rgb(165, 122, 42);
}


ul.mobile-header-nav li ul {
  margin-bottom: 5px;
}

ul.mobile-header-nav li ul li {
  border-bottom: none;
}

ul.mobile-header-nav li ul li a {
  padding: 10px;
  color: #aaa;
  font-weight: 500;
  border-radius: 4px;
}

ul.mobile-header-nav li ul li a:hover {
  background-color: #444;
  color: rgb(165, 122, 42);
}