footer.footer-area {
  padding: 70px 0;
  background-color: #000;
  margin-top: 30px;
}

ul.footer-nav {
  list-style-type: none;
  padding: 0;
  margin: 0 20px 0 0;
  color: #fff;
}

ul.footer-nav li {
  display: flex;
}

ul.footer-nav li a {
  padding: 10px;
  color: #fafafa;
}

ul.footer-nav li a:hover {
  color: rgb(165, 122, 42);
}

.col-info {
  margin: 0 30px 0 0;
}

.footer-discription {
  color: #fff;
  line-height: 1.7;
  min-width: 40%;
  max-width: 50%;
  flex: 1 1;
}

.footer-title {
  color: #fff;
}