.section-container {
  padding: 40px 0;
  background-color: #fff;
}

.section-container .starter {
  text-align: center;
  max-width: 50%;
  margin: 0 auto 30px auto;
}

@media screen and (max-width: 600px) {
  .section-container .starter {
    max-width: 98%;
    padding: 20px;
  }
}
.section-container .starter h2 {
  font-size: 43px;
  font-weight: 900;
  margin: 0 0 20px 0 !important;
  padding: 0;
}
.section-container .starter p {
  font-size: 19px;
  font-weight: 400;
}

.section-grider {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.3rem;
  grid-auto-flow: dense;
}
.section-packer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 30px;
  min-height: 150px;
  transition: 0.7s ease-in-out;
}
.section-packer:hover {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 5px 5px 96px rgba(164, 3, 100, 0.2);
}

.section-packer .feature-icon {
  height: 40px;
  display: inline-flex;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
  margin-bottom: 10px;
}
.section-packer .feature-icon img {
  height: 100%;
  max-width: 100%;
}

.section-packer p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  text-align: left !important;
  font-size: 1.15rem;
  font-weight: 500;
  text-align: center;
  color: #444;
}

.section-packer a {
  padding: 8px 0;
  color: #af0961;
  margin: 5px 0 0 0;
  font-weight: 900;
  border-radius: 35px;
  transition: 0.3s ease-in-out;
}
.section-packer a:hover {
  color: rgb(51, 11, 11);
}

.section-packer a:hover > .section-packer .feature-icon img {
  filter: drop-shadow(4px 15px 9px rgb(0 0 0 / 0.9));
}

.result-error {
  padding: 20px;
  text-align: center;
  font-weight: 400;
  border-radius: 8px;
  margin: 5px 10px;
  font-style: italic;
}
.result-error span {
  padding: 10px;
  color: rgb(232, 92, 92);
  font-size: 40px;
}
.result-error h3 {
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  color: #999;
}


.profile-card{
  
}