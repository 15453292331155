.txt-white {
  color: #fff !important;
}



.icon-pane .css-i4bv87-MuiSvgIcon-root {
  width: 0.7em;
  height: 0.7em;
  font-size: 3.56rem !important;
}

@media screen and (max-width: 600px) {
  .page-head h2 {
    font-size: 19px;
    margin-top: 4px !important;
  }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 450px !important;
}

@media screen and (max-width: 500px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 4px;
    box-shadow: none !important;
    /*  min-height: calc(100% - 1px) !important; */
    min-width: calc(100% - 1px) !important;
  }

  .css-1wxmkz5-MuiPaper-root-MuiCard-root {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: none !important;
  }
}




.bg-diff {
  background-color: #e0e4e8;
}

.bg-differ {
  background-color: #d5dade;
}

.g0,
.g1,
.g2,
.g3,
.g4,
.g5,
.g6,
.g7,
.g8 {
  /*color: #000;*/
  transition: 0.3s ease-in-out;
}

.g0 {
  background-color: #dca2ed;
}

.g1 {
  background-color: #db5e05;


  background: -webkit-linear-gradient(20deg, #db5e05 20%, rgba(0, 0, 0, 0) 36%),
    -webkit-radial-gradient(farthest-corner at 10% 0%, #e98d4b 70%, #f5b181 70%);
  background: -o-linear-gradient(20deg, #db5e05 20%, rgba(0, 0, 0, 0) 36%),
    -o-radial-gradient(farthest-corner at 10% 0%, #e98d4b 70%, #f5b181 70%);
  background: -moz-linear-gradient(20deg, #db5e05 20%, rgba(0, 0, 0, 0) 36%),
    -moz-radial-gradient(farthest-corner at 10% 0%, #e98d4b 70%, #f5b181 70%);
  background: linear-gradient(20deg, #db5e05 20%, rgba(0, 0, 0, 0) 36%),
    radial-gradient(farthest-corner at 10% 0%, #e98d4b 70%, #f5b181 70%);
  /**/
}

.g2 {
  background-color: #98c852;
  background: -webkit-linear-gradient(20deg, #98c852 20%, rgba(0, 0, 0, 0) 36%),
    -webkit-radial-gradient(farthest-corner at 10% 0%, #b6e471 70%, #d3f69f 70%);
  background: -o-linear-gradient(20deg, #98c852 20%, rgba(0, 0, 0, 0) 36%),
    -o-radial-gradient(farthest-corner at 10% 0%, #b6e471 70%, #d3f69f 70%);
  background: -moz-linear-gradient(20deg, #98c852 20%, rgba(0, 0, 0, 0) 36%),
    -moz-radial-gradient(farthest-corner at 10% 0%, #b6e471 70%, #d3f69f 70%);
  background: linear-gradient(20deg, #98c852 20%, rgba(0, 0, 0, 0) 36%),
    radial-gradient(farthest-corner at 10% 0%, #b6e471 70%, #d3f69f 70%);
}

.g3 {
  background-color: #2896a3;
  background: -webkit-linear-gradient(20deg, #2896a3 20%, rgba(0, 0, 0, 0) 36%),
    -webkit-radial-gradient(farthest-corner at 10% 0%, #7bcdd6 70%, #b7ebf1 70%);
  background: -o-linear-gradient(20deg, #2896a3 20%, rgba(0, 0, 0, 0) 36%),
    -o-radial-gradient(farthest-corner at 10% 0%, #7bcdd6 70%, #b7ebf1 70%);
  background: -moz-linear-gradient(20deg, #2896a3 20%, rgba(0, 0, 0, 0) 36%),
    -moz-radial-gradient(farthest-corner at 10% 0%, #7bcdd6 70%, #b7ebf1 70%);
  background: linear-gradient(20deg, #2896a3 20%, rgba(0, 0, 0, 0) 36%),
    radial-gradient(farthest-corner at 10% 0%, #7bcdd6 70%, #b7ebf1 70%);
}

.g4 {
  background-color: #eb0583;
  background: -webkit-linear-gradient(20deg, #eb0583 20%, rgba(0, 0, 0, 0) 36%),
    -webkit-radial-gradient(farthest-corner at 10% 0%, #e871b2 70%, #f5aed5 70%);
  background: -o-linear-gradient(20deg, #eb0583 20%, rgba(0, 0, 0, 0) 36%),
    -o-radial-gradient(farthest-corner at 10% 0%, #e871b2 70%, #f5aed5 70%);
  background: -moz-linear-gradient(20deg, #eb0583 20%, rgba(0, 0, 0, 0) 36%),
    -moz-radial-gradient(farthest-corner at 10% 0%, #e871b2 70%, #f5aed5 70%);
  background: linear-gradient(20deg, #eb0583 20%, rgba(0, 0, 0, 0) 36%),
    radial-gradient(farthest-corner at 10% 0%, #e871b2 70%, #f5aed5 70%);
}

.g5 {
  background-color: #0c0110;
}

.g6 {
  background-color: #772b8f;
  background: -webkit-linear-gradient(20deg, #772b8f 20%, rgba(0, 0, 0, 0) 36%),
    -webkit-radial-gradient(farthest-corner at 10% 0%, #c386d6 70%, #d29de2 70%);
  background: -o-linear-gradient(20deg, #772b8f 20%, rgba(0, 0, 0, 0) 36%),
    -o-radial-gradient(farthest-corner at 10% 0%, #c386d6 70%, #d29de2 70%);
  background: -moz-linear-gradient(20deg, #772b8f 20%, rgba(0, 0, 0, 0) 36%),
    -moz-radial-gradient(farthest-corner at 10% 0%, #c386d6 70%, #d29de2 70%);
  background: linear-gradient(20deg, #772b8f 20%, rgba(0, 0, 0, 0) 36%),
    radial-gradient(farthest-corner at 10% 0%, #c386d6 70%, #d29de2 70%);

}

.g7 {
  background-color: #05538b;
  background: -webkit-linear-gradient(20deg, #05538b 20%, rgba(0, 0, 0, 0) 36%),
    -webkit-radial-gradient(farthest-corner at 10% 0%, #6aa9d7 70%, #bedcf1 70%);
  background: -o-linear-gradient(20deg, #05538b 20%, rgba(0, 0, 0, 0) 36%),
    -o-radial-gradient(farthest-corner at 10% 0%, #6aa9d7 70%, #bedcf1 70%);
  background: -moz-linear-gradient(20deg, #05538b 20%, rgba(0, 0, 0, 0) 36%),
    -moz-radial-gradient(farthest-corner at 10% 0%, #6aa9d7 70%, #bedcf1 70%);
  background: linear-gradient(20deg, #05538b 20%, rgba(0, 0, 0, 0) 36%),
    radial-gradient(farthest-corner at 10% 0%, #6aa9d7 70%, #bedcf1 70%);
}

.g8 {
  background-color: #fbec17;
}


.buy-bg {
  /*
  background-color: #0052fe;
  background: -webkit-linear-gradient(20deg, #fafafa 20%, rgba(0, 0, 0, 0) 36%),
    -webkit-radial-gradient(farthest-corner at 10% 0%, #f8fcff 70%, #bedcf1 70%);
  background: -o-linear-gradient(20deg, #fafafa 20%, rgba(0, 0, 0, 0) 36%),
    -o-radial-gradient(farthest-corner at 10% 0%, #f8fcff 70%, #bedcf1 70%);
  background: -moz-linear-gradient(20deg, #fafafa 20%, rgba(0, 0, 0, 0) 36%),
    -moz-radial-gradient(farthest-corner at 10% 0%, #f8fcff 70%, #bedcf1 70%);
  background: linear-gradient(20deg, #fafafa 20%, rgba(0, 0, 0, 0) 36%),
    radial-gradient(farthest-corner at 10% 0%, #f8fcff 70%, #bedcf1 70%);
  background-image: linear-gradient(to right, #fafafa, #bedcf1, #e3eff8);
*/
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  background-color: transparent;
}

.buy-bg-overlay {
  opacity: 0.96;
  /*  background-image: linear-gradient(to right, #fafafa, #e3eff8, #183951);
  background-image: linear-gradient(to right, #fefefe, rgb(208, 180, 197), #e3eff8);
   */
  background-color: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0 !important;
  border-radius: 15px;

}

.main-bg-grad {
  /*background-image: linear-gradient(to right, #fefefe, rgb(208, 180, 197), #e3eff8);

  
  background-color: #fafafa;*/
}

.bg-white {
  background: -webkit-linear-gradient(20deg, #fefefe 40%, #efefef 30%),
    -webkit-radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fdfdfd 70%);
  background: -o-linear-gradient(20deg, #fefefe 40%, #efefef 30%),
    -o-radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fdfdfd 70%);
  background: -moz-linear-gradient(20deg, #fefefe 40%, #efefef 30%),
    -moz-radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fdfdfd 70%);
  background: linear-gradient(20deg, #fefefe 40%, #efefef 30%),
    radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fdfdfd 70%);
}

.white-bg {
  background-color: #fff;
}

.bg-grax {
  background: -webkit-linear-gradient(20deg, #cfcfcf 40%, rgba(0, 0, 0, 0) 30%),
    -webkit-radial-gradient(farthest-corner at 0% 0%, #ccc 70%, #ddd 70%);
  background: -o-linear-gradient(20deg, #cfcfcf 40%, rgba(0, 0, 0, 0) 30%),
    -o-radial-gradient(farthest-corner at 0% 0%, #ccc 70%, #ddd 70%);
  background: -moz-linear-gradient(20deg, #cfcfcf 40%, rgba(0, 0, 0, 0) 30%),
    -moz-radial-gradient(farthest-corner at 0% 0%, #ccc 70%, #ddd 70%);
  background: linear-gradient(20deg, #cfcfcf 40%, rgba(0, 0, 0, 0) 30%),
    radial-gradient(farthest-corner at 0% 0%, #ccc 70%, #ddd 70%);
}

.bga {
  background: -webkit-linear-gradient(20deg, #aeaeae 20%, rgba(0, 0, 0, 0) 36%),
    -webkit-radial-gradient(farthest-corner at 10% 0%, #ccc 70%, #ddd 70%);
  background: -o-linear-gradient(20deg, #aeaeae 20%, rgba(0, 0, 0, 0) 36%),
    -o-radial-gradient(farthest-corner at 10% 0%, #ccc 70%, #ddd 70%);
  background: -moz-linear-gradient(20deg, #aeaeae 20%, rgba(0, 0, 0, 0) 36%),
    -moz-radial-gradient(farthest-corner at 10% 0%, #ccc 70%, #ddd 70%);
  background: linear-gradient(20deg, #aeaeae 20%, rgba(0, 0, 0, 0) 36%),
    radial-gradient(farthest-corner at 10% 0%, #ccc 70%, #ddd 70%);
}

.bgx {
  background-image: radial-gradient(ellipse farthest-corner at 0 140%,
      rgb(92, 114, 102) 10%,
      rgb(93, 141, 176) 40%,
      #849894 70%) !important;
  color: white;
}


.txt-sm {
  font-size: 90%;
  font-weight: 400;
}

.txt-xsm {
  font-size: 80%;
  font-weight: 400;
}

.sm-show-inline,
.sm-show-inline-block,
.sm-show-block,
.sm-show-flex,
.sm-show-inline-flex {
  display: none !important;
}

.sm-hide-inline {
  display: inline !important;
}

.sm-hide-inline-block {
  display: none !important;
}

.sm-hide-block {
  display: block !important;
}

.sm-hide-flex {
  display: flex !important;
}

.sm-hide-inline-flex {
  display: inline-flex !important;
}

@media screen and (max-width: 500px) {

  .sm-hide-inline,
  .sm-hide-inline-block,
  .sm-hide-block,
  .sm-hide-flex,
  .sm-hide-inline-flex {
    display: none !important;
  }

  .sm-show-block {
    display: block !important;
  }

  .sm-show-inline {
    display: inline !important;
  }

  .sm-show-inline-flex {
    display: inline-block !important;
  }

  .sm-show-flex {
    display: flex !important;
  }
}


.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid-card {
  min-height: 130px;
  display: flex;
  flex-direction: column;
  background-color: #777;
  position: relative;
  -webkit-transition: all 5s ease-in-out;
  -moz-transition: all 5s ease-in-out;
  -ms-transition: all 5s ease-in-out;
  -o-transition: all 5s ease-in-out;
  transition: all 5s ease-in-out;
  /*transform: skewX(-0.06turn);
  padding: 20px;*/
  border-radius: 15px;
}

.grid-card,
.grid-card a,
a.grid-card {
  color: #444;
}

.txt-white {
  color: #fff !important;
}

.pane-title {
  font-size: 1.03rem;
  font-weight: 900;
  text-transform: uppercase;
  /*text-shadow: -1px 1px 1px #000;
 transform: skewX(0.06turn);*/
}

.icon-pane {
  width: 100%;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.big-desc {
  font-size: 40px;
  font-weight: 900;
}

.icon-pane .css-i4bv87-MuiSvgIcon-root {
  width: 0.7em;
  height: 0.7em;
  font-size: 3.56rem !important;
}

.reg-modal-width {
  min-width: 550px;
}

@media screen and (max-width: 560px) {
  .reg-modal-width {
    min-width: 99%;
  }
}

.modal-items-container {
  padding: 20px;
}

.border-radius {
  border-radius: 4px;
}

.page-top {
  padding: 20px;
}

.page-info {
  width: 80%;
  margin: 0 auto;
  color: "#fff"
}




.bgx {
  background-image: radial-gradient(ellipse farthest-corner at 0 140%,
      rgb(92, 114, 102) 10%,
      rgb(93, 141, 176) 40%,
      #849894 70%) !important;
  color: white;
}




.bonus-warn {
  font-size: 2em;
}

.color-sky-blue {
  color: #d1e7f8;
}

.color-success,
.color-approved {
  color: #00d285;
}

.color-error,
.color-cancelled {
  color: #eb5555;
}

.color-warn,
.color-pending {
  color: #faa52f;
}

.force-show {
  display: block !important;
}


.ucap {
  text-transform: uppercase;
}

.badge {
  padding: 2px 8px;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  border-radius: 3px;
  font-weight: 500;
  color: #fff;
  border: 1px solid;
  min-width: 60px;
  vertical-align: middle;
}

.badger {
  padding: 8px 15px;
  text-align: center;
  border-radius: 3px;
  font-weight: 500;
  color: #fff;
  border: 1px solid;
  vertical-align: middle;
}

.badge.xsm {
  font-size: 10px;
  line-height: 13px;
}

.badge-regular-btn {
  background: #2368a0;
  border-color: #2368a0;
}

.badge-success,
.badge-active,
.badge-approved {
  background: #00d285;
  border-color: #00d285;
}

.badge-pending,
.badge-inactive,
.badge-onhold {
  background-color: #faa83d;
  border-color: #faa83d;
}

.badge-error,
.badge-cancelled {
  background-color: #c42f2f;
  border-color: #c42f2f;
}

.badge-info {
  background-color: #444;
  border-color: #444;
}


.tbl-row {
  display: flex;
  flex-direction: row;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.value-span {
  font-weight: 900;
  padding-left: 10px;
}

.crypto-desc-div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f0f4f5;
  border-radius: 6px;
}

.crypto-desc-div p {
  padding: 0;
  margin: 0 0 4px 0;
  font-weight: 500;
  font-size: 90%;
  font-style: italic;
}

.crypto-desc-div div {
  margin-bottom: 5px;
}


.pulse {
  animation: pulsate 1s ease-out;
  -webkit-animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}

.signal-box {
  font-weight: 700;
  font-size: 0.9rem;
  margin: 0 5px 0 0;
}

.signal-icon {
  color: rgb(255, 81, 18) !important;
  font-size: 0.5rem;
}

.signal-text {
  color: orange !important;
}

.coler {
  line-height: 1.2 !important;
  margin-bottom: 0;
}

.coler h1,
.coler h2,
.coler h3,
.coler h4,
.coler h5 {
  margin-bottom: 0;
  line-height: 1;
}

.coler h3 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.coler h4 {
  font-weight: 400;
  font-size: 0.85rem;
}

.coler h5 {
  font-weight: 400;
  font-size: 0.75rem;
}

.coler p {
  margin: 0;
  padding: 2px 0 0 0;
  font-size: 85%;
  color: #666;
}

.stat-col {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 10px;
}

.count-span {
  font-weight: 900;
  text-align: center;
  font-size: 2rem;
}

@media screen and (max-width: 600px) {
  .count-span {
    font-weight: 900;
    text-align: center;
    font-size: 1.5rem;
  }
}

.small-footnote {
  font-size: 85%;
  padding: 0;
  margin: 6px 0 0 0;
}

.desc-span {
  font-size: 75%;
  text-align: center;
}

.single_cards {}

.single_cards.imh {
  max-height: 400px;
  margin: 20px 10px;
}

.single_cards img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
}

.buy-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #a5b4c1;
  margin-bottom: 20px;
  align-items: center;
  padding: 15px;
}

.coka {
  z-index: 4 !important;
  border-radius: 15px;
}

.stage-titler {
  font-size: 1.2rem;
}

.stage-icon {
  font-size: 30px;
  margin-right: 10px;
}

.done-text {}

.done-text h4,
.total-text h4 {
  font-weight: 300 !important;
  font-size: 0.7rem !important;
}

.txt-pcs {
  font-weight: 900;
  margin-top: 6px;
  display: inline-block;
}

.rate-span {
  display: flex;
  margin-top: 10px;
}

.rate-span h3 {
  font-size: 1rem;
}

.title-ba {
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.tids {
  font-size: 0.8rem;
  text-transform: capitalize;
  font-weight: 300;
  margin-bottom: 4px;
}

ul.coin-icons {
  list-style-type: none;
  margin: 0;
  padding: 10px;
}

ul.coin-icons li {
  display: inline-block;
  height: 25px;
  width: 25px;
  margin: 0 2px;
}

ul.coin-icons li img {
  max-width: 100%;
  max-height: 100%;
}

.temp-card {
  min-height: 300px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 15px;
}

.bor-top {
  border-top: 1px solid #ddd;
  padding-top: 30px;
}

ul.token-balance-list {
  justify-content: flex-end;
}

ul.token-balance-list li {
  font-weight: 900;
}

h6.card-sub-title {
  padding-right: 10px;
}

.token-balance.token-balance-s2 {
  text-align: right;
}

.justify-end {
  justify-content: flex-end;
}

.row {
  display: flex;
  flex-direction: row;
}

.guttar-vr-20px {}

.card-image {
  margin-right: 24px;
}

.card-image img {
  max-height: 180px;
  width: auto;
}

.main-white-title {
  color: #fff !important;
}

.white-main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.whitepaper-header-container {
  height: 60px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.whitepaper-body-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: calc(100% - 60px);
}

.whitepaper-side-nav {
  background-color: #fefefe;
  border-right: 1px solid #ddd;
  width: 320px;
  overflow-y: auto;
  max-height: 100%;
  transition: .3s;
}

.mobile-ham {
  display: none;
  padding-left: 15px;
}

.mobile-ham a {
  font-size: 23px;
}

.desk-show {
  display: inline-flex;
}

.expand-width {
  width: 320px !important;
}

@media screen and (max-width: 600px) {
  .whitepaper-side-nav {
    width: 1px;
  }

  .mobile-ham {
    display: inline-block;
  }

  .desk-show {
    display: none;
  }
}

.ham-logo {
  height: 40px;
  margin-left: 10px;
}

.ham-logo img {
  height: 100%;
}

.whitepaper-page-content {
  overflow-y: auto;
  flex: 1;
  position: relative;
  overflow-y: auto;
  max-height: 100%;
}

.card-content {
  display: block;
  max-height: 100%;
  overflow: auto;
  padding-bottom: 20px;
}

ul.whitepaper-list {
  list-style-type: none;
  padding: 20px 20px 20px 30px;
}

ul.whitepaper-list li {
  display: block;
  padding: 0;
  margin: 0 0 2px 0;
}

ul.whitepaper-list li a {
  display: block;
  transition: .5s;
  padding: 8px 10px;
  font-weight: 700;
  color: #444;
}

ul.whitepaper-list li ul {
  padding-left: 10px;
}

ul.whitepaper-list li ul li a {
  padding: 3px 8px !important;
  font-weight: 500;
  display: block;
  color: #555;
  font-size: 98%;
  border-radius: 5px;
}

ul.whitepaper-list li ul li a:hover,
ul.whitepaper-list li ul li a.active {
  background-color: rgb(220, 237, 240);
  color: #222;
}

.uppercase {
  text-transform: uppercase;
}

.banner-spot {
  display: block;
  max-width: 100%;
  margin: 20px 0;
}

.banner-spot img {
  max-width: 100%;
}

.faq-question-details {
  font-size: 1.1rem;
  line-height: 1.6;
}

.profile-card-container,
a.profile-card-container {
  width: 100%;
  min-height: 350px;
  border: 1px solid #b09898;
  display: flex;
  flex-direction: column;
  transition: .5s;
  color: #444;
}

.profile-card-container:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, .3), 0 15px 12px rgba(0, 0, 0, .22);
}

.profile-card-content {
  border-top-style: solid;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1 1;
  position: relative;
  overflow: hidden;
}

.profile-card-thumb-image-holder {
  height: 300px;
  background-color: #fafafa;
  object-fit: contain;
  position: relative;
}

.profile-card-thumb-image-holder img {
  border-radius: 0;
  max-width: 100%;
  min-height: 50%;
}

.italic {
  font-style: italic;
  font-size: 96%;
}

.meta-pane,
a.meta-pane {
  position: absolute;
  left: 50%;
  width: 60%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  transition: .5s;
  padding: 10px 20px;
  border: 1px solid rgb(165, 122, 42);
  background-color: #fff;
  text-align: center;
}


.profile-card-container:hover .meta-pane {
  width: 100%;
  text-align: left;
  background-color: #fafafa;
}

.meta-pane h3,
a.meta-pane h3 {
  font-weight: 900;
  font-size: 1.1rem;
  color: #444;
}

.meta-pane .desc-text {
  font-weight: 600;
  color: rgb(165, 122, 42);
  transition: .6s;

}

.container-pad-v {
  padding: 50px 0;
  z-index: 3;
  position: relative;
}

.margin-auto {
  margin: 0 auto;
}

.w-50-resp {
  width: 50%;
}

.w-60-resp {
  width: 60%;
}

.w-70-resp {
  width: 70%;
}

.w-80-resp {
  width: 80%;
}

@media screen and (max-width: 600px) {

  .w-50-resp,
  .w-60-resp,
  .w-70-resp,
  .w-80-resp {
    width: 100%;
  }
}

.ipo {
  font-size: 15px !important;
  line-height: 19px !important;
  color: rgb(165, 122, 42);
  font-family: 'Source Sans 3', sans-serif !important;
}

.testimony-cover {
  position: relative;
  border-radius: 10px;
  margin: 60px 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.testimony-space {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .testimony-space {
    flex-direction: column;
  }
}

.testimony-overlay {
  position: absolute;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.15;
  z-index: 0;
}

.testimony-overlay-quote {
  position: absolute;
  width: 70px;
  height: 70px;
  background-color: rgb(165, 122, 42);
  top: -35px;
  color: #fff;
  font-size: 36px;
  line-height: 75px;
  text-align: center;
  font-weight: 900;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 3;
  border-radius: 50%;

}




.testimony-cover:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #fff;
  opacity: 0.15;
  border-width: 20px;
  margin-top: -20px;
}



@media screen and (max-width: 800px) {
  .testimony-cover:after {
    top: calc(100% + 20px);
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #fff;
    opacity: 0.15;
    border-width: 20px;
    margin-left: -20px;
  }
}

.testimony-content {
  padding: 37px;
  z-index: 1;
  position: relative;
  line-height: 1.6;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}

.profile-imager {
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pg-title {
  color: rgb(165, 122, 42);
  font-weight: 900;
  font-size: 1.2rem;
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-style: italic;
  margin-top: 20px;
}

.news-card {
  position: relative;
  height: 450px;
  background-color: #f2f2f2;
  overflow: hidden;
  padding-bottom: 20px;
}



.news-card::after {
  position: absolute;
  height: 20px;
  left: 0;
  bottom: 0;
  width: 100%;
  content: " ";
  background-color: #f2f2f2;
}

.thumb-pane {
  min-height: 190px;
  position: relative;
  background-color: #777;
  border: 1px solid #f2f2f2;
  display: block;
}

.category-span {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.category-span a {
  background-color: rgb(165, 122, 42);
  border: 1px solid rgb(165, 122, 42);
  color: #fff;
  display: inline-block;
  line-height: 1.1;
  padding: 4px 8px;
  font-size: 85%;
}

.category-span a:hover {
  color: #fff;
  background-color: #000;
}

.news-content-area {
  position: relative;
  padding: 15px;
}


.news-content-area h3 {
  font-weight: 900;
  position: relative;
  margin-bottom: 25px;
  line-height: 1.6;
}

.news-content-area h3 a {
  color: #444;
  transition: .3s;
}

.news-content-area h3 a:hover {
  color: rgb(165, 122, 42);
}

.news-content-area h3:after {
  height: 2px;
  left: 0;
  width: 30%;
  position: absolute;
  background-color: rgb(165, 122, 42);
  bottom: -10px;
  content: " ";
}

.date-space {
  color: rgb(165, 122, 42);
  font-weight: 500;
  font-size: 85%;
}

.news-main {
  padding: 20px;
  word-break: break-all;
  line-height: 1.5;

}

.news-main img {
  max-width: 100%;
}

.news-body {
  margin-bottom: 20px;
}

.related-card-header {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fafafa;
}

.list-link {
  color: #444;
  line-height: 1.2;
}

.comment-cover {
  border: 1px dashed rgb(165, 122, 42);
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fbfbfb;

}

.comment-content {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.comment-body {
  line-height: 1.4;
  font-size: 97%;
  margin-top: 10px;
}

.post-comment-bar {
  padding: 4px 15px;
  margin-bottom: 20px;
  border: 1px dashed rgb(165, 122, 42);
}


.gallery-content-pane {
  display: block;
  height: 240px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-color: #8f6464;
  border-radius: 12px;
  overflow: hidden;
  transition: .5s;
}

.gallery-content-pane::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.gallery-content-pane iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery-content-pane img {
  width: 100%;
}

.gallery-item-details {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 10px;
  z-index: 20;
  background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5) 60%,
      transparent);
  line-height: 1.3;
  color: #fff;
  text-align: center;
  left: 0;
  transition: .5s;
}

.gallery-item-details h4 {
  font-weight: 700;
}

.gallery-item-details .sub-details {
  font-weight: 300;
  font-size: 80%;
}

.gallery-content-pane:hover .gallery-item-details {
  min-height: 30%;
}

.my-carousel {
  background-color: #160821;
  border-radius: 3px dashed;
  display: flex;
  flex-grow: 1 1;
  height: 500px;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .my-carousel {
    height: 350px;
  }
}

@media screen and (max-width: 400px) {
  .my-carousel {
    height: 300px;
  }
}

.carousel-item-container {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  object-fit: contain;
  position: absolute;
  right: 0;
  top: 0;
  width: 100% !important;
}

.carousel-item-container img {
  height: auto;
  object-fit: contain;
  width: auto;
}

.caption-container {
  bottom: 0;
  height: auto;
  left: 50%;
  max-width: 100%;
  padding: 20px;
  position: absolute;
  text-shadow: 1px 1px 1px #000;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: 2s ease-in-out;
  width: 100%;
}

.caption-container-overlay {
  background-color: #000;
  height: 100%;
  left: 0;
  opacity: .4;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.caption-container-text {
  color: #fff !important;
  font-size: 1.1em;
  font-weight: 900;
  position: relative;
  z-index: 2;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 0.7rem;
  grid-auto-flow: dense;
  z-index: 1000;
  position: relative;
}

.gal-pop {
  position: relative;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  align-content: stretch;
  min-height: 280px;
  max-height: 280px;
  z-index: 1;
}

.gal-pop img {
  max-width: 100%;
  max-height: 100%;
}

.qedit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 900000000;
}

.qedit a {
  color: red;
  display: inline-block;
  margin-left: 4px;
}

.pic-sel {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: orangered;
  font-size: 30px;
  z-index: 2;
}

.pic-sel input[type="file"] {
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
}

.css-1hju3x6-MuiPaper-root-MuiDialog-paper,
.css-hz1bth-MuiDialog-container {
  z-index: 90000 !important;
}

.float-left {
  float: left;
  padding-bottom: 10px;
}

.leader-pic {
  max-height: 400px;
  min-height: 350px;
  width: 320px;
  float: left;
  padding: 10px;
  border-radius: 1px solid #bbb;
}

.leader-pic img {
  min-width: 100%;
  max-width: 100%;
}